import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import PostPreview from '../PostPreview'
import Pagination from '../Pagination'
import Form from '../Form'
import styles from './style.module.sass'

export default ({ data, posts, type, pageTitle, pathPrefix, skip, limit, numberOfPages, pageNumber }) => {
  const { pageSettings, blogSettings } = data
  const postsOnPage = posts.slice(skip, skip + limit)
  const postsBeforeForm = type === 'podcasts' ? 4 : 6

  return (
    <div className={styles.blog}>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h1 className={styles.title}>{pageTitle}</h1>
          <Link to={pageSettings.path} className={styles.link}>
            View All Resources
          </Link>
        </div>

        <div className={cx(styles.list, styles[type])}>
          {postsOnPage &&
            postsOnPage.map((post, index) => (
              <>
                {index < postsBeforeForm && (
                  <PostPreview key={post._id} {...post} pageSettings={pageSettings} blogSettings={blogSettings} />
                )}
              </>
            ))}
        </div>

        <div className={styles.form}>
          <Form />
        </div>

        <div className={cx(styles.list, styles[type])}>
          {postsOnPage &&
            postsOnPage.map((post, index) => (
              <>
                {index >= postsBeforeForm && (
                  <PostPreview key={post._id} {...post} pageSettings={pageSettings} blogSettings={blogSettings} />
                )}
              </>
            ))}
        </div>

        <Pagination numberOfPages={numberOfPages} pageNumber={pageNumber} pathPrefix={pathPrefix} />
      </div>
    </div>
  )
}
